<template>
  <div class='error-toast'
    :class="[{ 'visible': isVisible }, `type-${type}`]" :style="`--color: ${color}`">
    <div class="content-wrapper">
      <icon-warning :color="color" />
      <span class="text" v-html="text" />
    </div>
  </div>
</template>

<script>
import IconWarning from '@/components/shared/elements/icons/IconWarning';

export default {
  name: 'ErrorToast',
  props: {
    text: {
      type: String,
      required: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'error',
      validator: (value) => ['error', 'success'].indexOf(value) !== -1,
    },
  },
  components: {
    IconWarning,
  },
  data: () => ({
  }),
  created() {},
  computed: {
    color() {
      let result;
      switch (this.type) {
        case 'error':
          result = '#F22C2C';
          break;
        case 'success':
          result = '#71A818';
          break;
        default:
          break;
      }
      return result;
    },
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.error-toast {
  overflow: hidden;
  max-height: 0;
  transition: $transitionBaseSlow;
  opacity: 0;

  &.visible {
    max-height: 1000px;
    opacity: 1;
  }
}

.content-wrapper {
  border-radius: $globalBorderRadius;
  border: 2px solid var(--color);
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: var(--color);
  padding: 15px 9px 15px 28px;
}

.icon-warning {
  margin: 0 16px 0 0;
}
</style>
