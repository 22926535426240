<!-- eslint-disable -->
<template>
  <div class='icon-warning'>
    <svg id="Group_3246" data-name="Group 3246" xmlns="http://www.w3.org/2000/svg" width="27.322" height="25.773" viewBox="0 0 27.322 25.773">
      <path id="Path_38106" data-name="Path 38106" d="M26.768,34.268,17.106,16.48a4.007,4.007,0,0,0-6.891,0l-.016.028L.57,34.24a4.005,4.005,0,0,0,3.444,6.052h19.24a4.048,4.048,0,0,0,3.536-2.025A3.949,3.949,0,0,0,26.768,34.268Zm-14.71-11.6a1.6,1.6,0,0,1,3.205,0v6.41a1.6,1.6,0,1,1-3.205,0v-6.41Zm1.6,14.423a2.4,2.4,0,1,1,2.4-2.4A2.406,2.406,0,0,1,13.661,37.087Z" transform="translate(-0.001 -14.519)" :fill="color"/>
    </svg>
  </div>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconWarning',
  props: {
    color: {
      type: String,
      required: false,
      default: '#DC3435',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
</style>
